import React from 'react';

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="/privacy">Privacy</a>
          </li>
          <li className="list-inline-item">
            <a href="/terms">Terms</a>
          </li>
          <li className="list-inline-item">
            <a href="/faq">FAQ</a>
          </li>
          <li className="list-inline-item">
            <a href="mailto:info@nearo.co">Contact</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
